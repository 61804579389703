import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

function getSession(key) {
  return sessionStorage[key] ? JSON.parse(sessionStorage[key]) : "";
}

function setSession(key, palyload) {
  sessionStorage[key] = JSON.stringify(palyload);
}
import getters from './getters'

export default new Vuex.Store({
  state: {
    BG_imgs: {},
    PLAYER_NAME: "" || getSession("PLAYER_NAME"),
    PLAYER_IMAGE: "" || getSession("PLAYER_IMAGE"),
    PLAYER_URL: "" || getSession("PLAYER_URL"),
    COURSE_ID: null,
    SECTION_ID: null,
    PLAY: false,
    SHOW: "",
    IS_SHOW_PLAYER: "",
    IS_SHOW_MINI_PLAYER: "",
    nextPLAYER: null,
    HASBUY: "",
    UPLOAD_DATA: {},
    isPLay: false,
    isiphone: true,
    iphoneBjC: 'rgba(0,0,0,.7)', // 输入手机号弹窗背景颜色
    programmepageLurl: '',
    APPID: 'wxdd285be4b4977269',
    // APPID: 'wx79a82e8d3cba9b21', //夏落

    // 



    videoSECTION_ID:'' || getSession("videoSECTION_ID:"),
    // APPID:'wx6f9beacbd0bee9d2',


  },
  mutations: {
    Setiphone: (state, palyload) => {
      state.isiphone = palyload
    },
    SetiphoneBjC: (state, iphoneBjC) => {
      state.iphoneBjC = iphoneBjC
    },
    SetpageLurl: (state, palyload) => {
      state.programmepageLurl = palyload
    },
    PLAYER_BGimgs: (state, palyload) => {
      // 播放背景图
      state.BG_imgs = palyload;
      setSession("BG_imgs", palyload);
    },
    nextPLAYER: (state, palyload) => {
      //下一节的小节id
      state.nextPLAYER = palyload
    },
    PLAYER_NAME: (state, palyload) => {
      // 播放名字
      state.PLAYER_NAME = palyload;
      setSession("PLAYER_NAME", palyload);
    },
    PLAYER_IMAGE: (state, palyload) => {
      // 封面图
      state.PLAYER_IMAGE = palyload;
      setSession("PLAYER_IMAGE", palyload);
    },
    PLAYER_URL: (state, palyload) => {
      // 播放地址
      state.PLAYER_URL = palyload;
      setSession("PLAYER_URL", palyload);
    },
    COURSE_ID: (state, payload) => {
      state.COURSE_ID = payload
    },
    SECTION_ID: (state, payload) => {
      state.SECTION_ID = payload
    },
    isPLay: (state, palyload) => {
      state.isPLay = palyload
    },
    PLAY: (state, palyload) => {
      // 是否播放
      state.PLAY = palyload;
    },
    SHOW: (state, palyload) => {
      // 是否显示播放器
      state.SHOW = palyload;
    },
    IS_SHOW_PLAYER: (state, palyload) => {
      // 是否展开播放器
      state.IS_SHOW_PLAYER = palyload;
    },
    IS_SHOW_MINI_PLAYER: (state, palyload) => {
      // 是否显示小播放器
      state.IS_SHOW_MINI_PLAYER = palyload;
    },
    HASBUY: (state, palyload) => {
      // 是否已购买课程，控制小播放器位置
      state.HASBUY = palyload;
    },
    UPLOAD_DATA(state, palyload) {
      state.UPLOAD_DATA = palyload;
    },


    // 视频播放器的一些东西
    // 小节的sectionId的存储
    setvideoSECTION_ID:(state,palyload)=>{
      state.videoSECTION_ID = palyload;
      setSession("videoSECTION_ID", palyload);
    }
  },
  actions: {},
  modules: {},
  getters: getters,
});